import Alarm from './alarm.svg'
import ArrowRight from './arrow-right.svg'
import AttributeGourmet from './attribute-gourmet.svg'
import Balcony from './balcony.svg'
import Ball from './ball.svg'
import Bath from './bath.svg'
import Beer from './beer.svg'
import Bike from './bike.svg'
import Brand from './brand.svg'
import Building from './building.svg'
import Bus from './bus.svg'
import Camera from './camera.svg'
import Car from './car.svg'
import Card from './card.svg'
import Charger from './charger.svg'
import ChevronDown from './chevron-down.svg'
import ChevronLeft from './chevron-left.svg'
import ChevronRight from './chevron-right.svg'
import ChevronUp from './chevron-up.svg'
import Close from './close.svg'
import Coffee from './coffee.svg'
import Computer from './computer.svg'
import Concierge from './concierge.svg'
import Cool from './cool.svg'
import Copa from './copa.svg'
import Countertops from './countertops.svg'
import DigitalLocker from './digital-locker.svg'
import Dinner from './dinner.svg'
import DoubleHouse from './double-house.svg'
import Download from './download.svg'
import EcoHouse from './eco-house.svg'
import EcoTrash from './eco-trash.svg'
import Edicule from './edicule.svg'
import Elevator from './elevator.svg'
import EyeControl from './eye-control.svg'
import Fence from './fence.svg'
import FileShield02 from './file-shield-02.svg'
import Film from './film.svg'
import Fire from './fire.svg'
import Fitness from './fitness.svg'
import Flag from './flag.svg'
import FullyWired from './fully-wired.svg'
import Game from './game.svg'
import Garage from './garage.svg'
import Garden from './garden.svg'
import Generator from './generator.svg'
import Gourmet from './gourmet.svg'
import Gravel from './gravel.svg'
import Grill from './grill.svg'
import Home02 from './home-02.svg'
import HomeShield3 from './home-shield 3.svg'
import Hospital from './hospital.svg'
import Integration from './integration.svg'
import Intercom from './intercom.svg'
import Isometric1 from './isometric-1.svg'
import Isometric2 from './isometric-2.svg'
import Isometric3 from './isometric-3.svg'
import Isometric4 from './isometric-4.svg'
import Ladder from './ladder.svg'
import Lake from './lake.svg'
import Laundry from './laundry.svg'
import Leaf from './leaf.svg'
import Lipstick from './lipstick.svg'
import Mail from './mail.svg'
import Man from './man.svg'
import MapPin from './map-pin.svg'
import Microondas from './microondas.svg'
import Mountain from './mountain.svg'
import Office from './office.svg'
import Party from './party.svg'
import People from './people.svg'
import Pet from './pet.svg'
import PhoneSolid from './phone-solid.svg'
import Phone from './phone.svg'
import Plant from './plant.svg'
import Play from './play.svg'
import Playground from './playground.svg'
import PoolWater from './pool-water.svg'
import Pool from './pool.svg'
import Road from './road.svg'
import SandBall from './sand-ball.svg'
import School from './school.svg'
import Sea from './sea.svg'
import Security from './security.svg'
import Share from './share.svg'
import Smart from './smart.svg'
import SocialFacebook from './social-facebook.svg'
import SocialInstagram from './social-instagram.svg'
import SocialLinkedin from './social-linkedin.svg'
import SocialTelegram from './social-telegram.svg'
import SocialThreads from './social-threads.svg'
import SocialTiktok from './social-tiktok.svg'
import SocialTwitter from './social-twitter.svg'
import SocialWhatsapp from './social-whatsapp.svg'
import SocialYoutube from './social-youtube.svg'
import Sofa from './sofa.svg'
import SolarEnergy from './solar-energy.svg'
import Song from './song.svg'
import Soup from './soup.svg'
import Spa from './spa.svg'
import Square from './square.svg'
import Squash from './squash.svg'
import Stair from './stair.svg'
import Store from './store.svg'
import TennisBall from './tennis-ball.svg'
import Thermometer from './thermometer.svg'
import Tv from './tv.svg'
import View from './view.svg'
import Vigilance from './vigilance.svg'
import Wardrobe from './wardrobe.svg'
import Warehouse from './warehouse.svg'
import Water from './water.svg'
import Webcam from './webcam.svg'
import Wifi from './wifi.svg'
import Window from './window.svg'

const svg = {
  'alarm': Alarm,
  'arrow-right': ArrowRight,
  'attribute-gourmet': AttributeGourmet,
  'balcony': Balcony,
  'ball': Ball,
  'bath': Bath,
  'beer': Beer,
  'bike': Bike,
  'brand': Brand,
  'building': Building,
  'bus': Bus,
  'camera': Camera,
  'car': Car,
  'card': Card,
  'charger': Charger,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'close': Close,
  'coffee': Coffee,
  'computer': Computer,
  'concierge': Concierge,
  'cool': Cool,
  'copa': Copa,
  'countertops': Countertops,
  'digital-locker': DigitalLocker,
  'dinner': Dinner,
  'double-house': DoubleHouse,
  'download': Download,
  'eco-house': EcoHouse,
  'eco-trash': EcoTrash,
  'edicule': Edicule,
  'elevator': Elevator,
  'eye-control': EyeControl,
  'fence': Fence,
  'file-shield-02': FileShield02,
  'film': Film,
  'fire': Fire,
  'fitness': Fitness,
  'flag': Flag,
  'fully-wired': FullyWired,
  'game': Game,
  'garage': Garage,
  'garden': Garden,
  'generator': Generator,
  'gourmet': Gourmet,
  'gravel': Gravel,
  'grill': Grill,
  'home-02': Home02,
  'home-shield 3': HomeShield3,
  'hospital': Hospital,
  'integration': Integration,
  'intercom': Intercom,
  'isometric-1': Isometric1,
  'isometric-2': Isometric2,
  'isometric-3': Isometric3,
  'isometric-4': Isometric4,
  'ladder': Ladder,
  'lake': Lake,
  'laundry': Laundry,
  'leaf': Leaf,
  'lipstick': Lipstick,
  'mail': Mail,
  'man': Man,
  'map-pin': MapPin,
  'microondas': Microondas,
  'mountain': Mountain,
  'office': Office,
  'party': Party,
  'people': People,
  'pet': Pet,
  'phone-solid': PhoneSolid,
  'phone': Phone,
  'plant': Plant,
  'play': Play,
  'playground': Playground,
  'pool-water': PoolWater,
  'pool': Pool,
  'road': Road,
  'sand-ball': SandBall,
  'school': School,
  'sea': Sea,
  'security': Security,
  'share': Share,
  'smart': Smart,
  'social-facebook': SocialFacebook,
  'social-instagram': SocialInstagram,
  'social-linkedin': SocialLinkedin,
  'social-telegram': SocialTelegram,
  'social-threads': SocialThreads,
  'social-tiktok': SocialTiktok,
  'social-twitter': SocialTwitter,
  'social-whatsapp': SocialWhatsapp,
  'social-youtube': SocialYoutube,
  'sofa': Sofa,
  'solar-energy': SolarEnergy,
  'song': Song,
  'soup': Soup,
  'spa': Spa,
  'square': Square,
  'squash': Squash,
  'stair': Stair,
  'store': Store,
  'tennis-ball': TennisBall,
  'thermometer': Thermometer,
  'tv': Tv,
  'view': View,
  'vigilance': Vigilance,
  'wardrobe': Wardrobe,
  'warehouse': Warehouse,
  'water': Water,
  'webcam': Webcam,
  'wifi': Wifi,
  'window': Window,
}

export type SvgName = keyof typeof svg

export { svg }